import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Hero from '../components/Hero/Hero';
import Navbar from '../components/Navbar/Navbar';
import classes from './template.module.scss';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

// @ts-ignore
const Template: FC = ({ data, path }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Hero page={frontmatter.heroClass}>
        <Navbar pathname={path} />
        <div className={classes.TitleContainer}>
          <div className={classes.Title}>{frontmatter.title}</div>
        </div>
      </Hero>
      <div
        className={classes.ContentContainer}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  );
};

export default Template;

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        heroClass
      }
    }
  }
`;
